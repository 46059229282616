import {
  Button,
  Divider,
  IconButton,
  Switch,
  Tag,
  TagLabel,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import {
  FocusEventHandler,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  faCabinetFiling,
  faFolderMinus,
  faFolderOpen,
  faPaperPlane,
} from '@fortawesome/pro-regular-svg-icons';

import AccessControl from '../../components/AccessControl';
import { ChangeLlm } from '../../components/ChangeLlm.js';
import Feature from '../../components/Feature.js';
import FileSelector from './fileSelector/FileSelector.js';
import { FileSelectorModal } from './fileSelector/FileSelectorModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PromptLibraryModal from './promptLibrary/PromptLibraryModal';
import ResizeTextarea from 'react-textarea-autosize';
import { SharepointFileSelectionMap, decodeName } from 'common-ts';
import { useTranslation } from 'react-i18next';

type ChatInputProps = {
  answerOnTheWay: boolean;
  inputValue: string;
  collectionNames: Map<string, string>;
  isPowerModeEnabled: boolean;
  showSelectFilesWaring: boolean;
  selectedSharepointFileMap: SharepointFileSelectionMap;
  collectionSelectedFileInfoIdMap: Map<string, Map<string, string>> | undefined;
  onEsc: () => void;
  onSend: () => void;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement>;
  onFileSelect: (
    collectionId: string,
    files: { name: string; fileInfoId: string }[]
  ) => void;
  onFileUnselect: (
    collectionId: string,
    files: { name: string; fileInfoId: string }[]
  ) => void;
  onInputValueChange: (value: string) => void;
  onTogglePowerMode: () => void;
  onClearAllFiles: () => void;
  onUpdateSelectedSharepointFileMap: (
    updateValue: SetStateAction<SharepointFileSelectionMap>
  ) => void;
};

function ChatInput({
  answerOnTheWay,
  inputValue,
  collectionNames,
  isPowerModeEnabled,
  showSelectFilesWaring,
  selectedSharepointFileMap,
  collectionSelectedFileInfoIdMap,
  onEsc,
  onSend,
  onBlur,
  onFocus,
  onFileSelect,
  onFileUnselect,
  onInputValueChange,
  onTogglePowerMode,
  onClearAllFiles,
  onUpdateSelectedSharepointFileMap,
}: ChatInputProps) {
  const { t } = useTranslation();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isFileSelectorModalOpen, setIsFileSelectorModalOpen] = useState(false);
  const [isPromptLibraryModalOpen, setIsPromptLibraryModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        textAreaRef.current?.focus();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const textArea = useMemo(() => {
    return (
      <Textarea
        ref={textAreaRef}
        as={ResizeTextarea}
        maxLength={16384}
        resize={'none'}
        className="text-maia-text-dark h-full text-sm font-medium"
        maxRows={8}
        minHeight={'unset'}
        height={'unset'}
        autoFocus
        value={inputValue}
        width={'full'}
        flexGrow={2}
        variant={'unstyled'}
        placeholder={t('chat.messagePlaceholder')}
        rounded={'none'}
        onChange={(e) => {
          onInputValueChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (e.shiftKey) {
              return;
            }
            if (inputValue.length > 0 && !answerOnTheWay) {
              onSend();
            }
            e.preventDefault();
          } else if (e.key === 'Escape') {
            onEsc();
          }
        }}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  }, [inputValue, onSend]);

  const fileSelector = useMemo(() => {
    return (
      <FileSelector
        collectionSelectedFileInfoIdMap={collectionSelectedFileInfoIdMap}
        onFileSelect={onFileSelect}
        onFileUnselect={onFileUnselect}
        isPowerModeEnabled={isPowerModeEnabled}
        onTogglePowerMode={onTogglePowerMode}
        selectedSharepointFileMap={selectedSharepointFileMap}
        onUpdateSelectedSharepointFileMap={onUpdateSelectedSharepointFileMap}
      />
    );
  }, [
    collectionSelectedFileInfoIdMap,
    isPowerModeEnabled,
    selectedSharepointFileMap,
  ]);

  const selectedFileList = useMemo(() => {
    const collectionIds = Array.from(
      collectionSelectedFileInfoIdMap?.keys() ?? []
    );

    const removeAllButton =
      collectionIds.length > 0 ? (
        <Button
          display="flex"
          alignItems="center"
          leftIcon={
            <FontAwesomeIcon
              icon={faFolderMinus}
              className="text-maia-gray-900"
            />
          }
          className="text-maia-gray-900 items-center justify-between pr-2 font-medium"
          key="remove-all"
          variant="unstyled"
          onClick={() => {
            if (onClearAllFiles) {
              onClearAllFiles();
            }
          }}
        >
          {t('chat.removeAllCollections')}
        </Button>
      ) : null;

    return (
      <div className="flex w-full flex-wrap items-center py-2">
        {removeAllButton}
        {collectionIds.map((collectionId) => (
          <div key={collectionId}>
            <Tag
              id={collectionId}
              className="mr-2 flex-shrink-0"
              variant="subtle"
              rounded={'2'}
              colorScheme="maia-gray"
              onClick={() => {
                setIsFileSelectorModalOpen(true);
              }}
              style={{ cursor: 'pointer' }}
            >
              <TagLabel className="truncate">
                {`${decodeName(collectionNames.get(collectionId) ?? '')} (${
                  Array.from(
                    collectionSelectedFileInfoIdMap
                      ?.get(collectionId)
                      ?.keys() ?? []
                  ).length
                })`}
              </TagLabel>
            </Tag>
          </div>
        ))}
      </div>
    );
  }, [collectionSelectedFileInfoIdMap, collectionNames]);

  const sendButton = useMemo(
    () => (
      <IconButton
        icon={<FontAwesomeIcon icon={faPaperPlane} className="text-white" />}
        aria-label="send"
        size="sm"
        colorScheme="maia-accent"
        isDisabled={inputValue.length === 0 || answerOnTheWay}
        onClick={() => {
          onSend();
        }}
      />
    ),
    [inputValue, answerOnTheWay, onSend]
  );

  const dataButton = useMemo(
    () => (
      <Tooltip
        label={t('chat.noFilesSelectedInfo')}
        isOpen={showSelectFilesWaring}
        isDisabled
        hasArrow
      >
        <Button
          display="flex"
          alignItems="center"
          className="text-maia-text-dark font-medium"
          leftIcon={
            <FontAwesomeIcon
              icon={faFolderOpen}
              className="text-maia-text-dark"
            />
          }
          aria-label="select-data"
          variant="unstyled"
          onClick={() => {
            setIsFileSelectorModalOpen(true);
          }}
        >
          {t('chat.fileSelector.button')}
        </Button>
      </Tooltip>
    ),
    [inputValue, answerOnTheWay, onSend]
  );

  const promptButton = useMemo(
    () => (
      <Button
        display="flex"
        alignItems="center"
        className="text-maia-text-dark font-medium"
        leftIcon={
          <FontAwesomeIcon
            icon={faCabinetFiling}
            className="text-maia-text-dark"
          />
        }
        aria-label="select-prompt"
        variant="unstyled"
        onClick={() => {
          setIsPromptLibraryModalOpen(true);
        }}
      >
        Prompt
      </Button>
    ),
    [inputValue, answerOnTheWay, onSend]
  );

  const powerModeSwitch = useMemo(
    () => (
      <div className="flex w-full items-center justify-center gap-2">
        <div className="text-maia-text-dark whitespace-nowrap font-medium">
          Power Mode
        </div>
        <Switch
          isChecked={isPowerModeEnabled}
          onChange={onTogglePowerMode}
          colorScheme="maia-accent"
        />
      </div>
    ),
    [isPowerModeEnabled]
  );

  // const helpButton = useMemo(
  //   () => (
  //     <IconButton
  //       className=""
  //       icon={
  //         <FontAwesomeIcon
  //           icon={faQuestionCircle}
  //           className="text-maia-text-dark"
  //         />
  //       }
  //       aria-label="send"
  //       variant="unstyled"
  //       onClick={() => {
  //         // onSend();
  //       }}
  //     />
  //   ),
  //   [inputValue, answerOnTheWay, onSend]
  // );

  const modelSelect = useMemo(() => <ChangeLlm />, []);

  function onClosePromptLibraryModal() {
    setIsPromptLibraryModalOpen(false);
  }

  function handleUsePrompt(prompt: string | undefined) {
    setIsPromptLibraryModalOpen(false);
    if (!prompt) {
      return;
    }
    onInputValueChange(prompt);
  }

  return (
    <div
      className="border-maia-border flex
      w-full items-end rounded-md border bg-white
      shadow-md"
    >
      <div className="flex w-full flex-col items-start px-4 py-3">
        <div className="flex w-full items-end py-2">
          {textArea}
          {sendButton}
        </div>
        {Array.from(collectionSelectedFileInfoIdMap?.keys() ?? []).length >
          0 && (
          <>
            <Divider />
            <div className="w-full">{selectedFileList}</div>
          </>
        )}
        <Divider />
        <div className="flex w-full justify-between pt-4">
          <div className="flex items-center gap-4">
            {dataButton}
            <AccessControl allowedLicenses={['ENTERPRISE', 'PROFESSIONAL']}>
              {promptButton}
            </AccessControl>
          </div>
          <div className="flex items-center justify-end gap-4">
            <Feature name="powerMode">
              <>
                {powerModeSwitch}
                <Divider orientation="vertical" />
              </>
            </Feature>
            {modelSelect}
            {/* <Divider orientation="vertical" />*/}
            {/* {helpButton} */}
          </div>
        </div>
      </div>
      <FileSelectorModal
        isOpen={isFileSelectorModalOpen}
        onClose={() => setIsFileSelectorModalOpen(false)}
        fileSelector={fileSelector}
      />
      <AccessControl allowedLicenses={['ENTERPRISE', 'PROFESSIONAL']}>
        <PromptLibraryModal
          isOpen={isPromptLibraryModalOpen}
          onClose={onClosePromptLibraryModal}
          onUsePromptClicked={(prompt) => handleUsePrompt(prompt)}
        />
      </AccessControl>
    </div>
  );
}

export default ChatInput;
