import { Checkbox, IconButton, Skeleton } from '@chakra-ui/react';
import { Database, SharepointFileSelectionMap, zeros } from 'common-ts';
import { SetStateAction, useEffect, useState } from 'react';
import { faAngleRight, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import useSharepointSelectionStateHandlers from './useSharepointSelectionStateHandlers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListItem from '../../../components/ListItem';
import SharepointSiteFoldersAndFiles from './SharepointSiteFoldersAndFiles';
import { captureException } from '@sentry/react';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import { fetchApi } from '../../../utils/useApi';
import { twMerge } from 'tailwind-merge';
import { useBoundStore } from '../../../store/useBoundStore';
import { useToastManagerHook } from '../../../general/useToastManagerHook';
import { useTranslation } from 'react-i18next';

type SharepointSiteNavigatorProps = {
  className?: string;
  selectedFileMap: SharepointFileSelectionMap;
  onBackClick: () => void;
  onUpdateSelectedFileMap: (
    updateValue: SetStateAction<SharepointFileSelectionMap>
  ) => void;
};

function SharepointSiteNavigator({
  className,
  selectedFileMap,
  onBackClick,
  onUpdateSelectedFileMap,
}: SharepointSiteNavigatorProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const { showToast } = useToastManagerHook();

  const [loadingSites, setLoadingSites] = useState(true);
  const [availableSites, setAvailableSites] = useState<
    Database['public']['Views']['sharepoint_site_view']['Row'][]
  >([]);

  const {
    currentSiteId,
    files,
    folders,
    breadCrumbs,
    handleDeselectAll,
    handleFileSelectToggle,
    handleFolderSelectToggle,
    handleGoBackInCrumbs,
    handleGoToFolder,
    handleSelectAll,
    handleSiteSelectToggle,
    loading: loadingFolderContent,
    setCurrentSiteId,
    refetchCurrentFolderContents,
  } = useSharepointSelectionStateHandlers(
    selectedFileMap,
    onUpdateSelectedFileMap
  );

  /**
   * Refresh contents of the current folder and request syncing of sharepoint view permissions.
   */
  function handleFolderRefresh() {
    // This is a (potentially) long running process that we have decided to not inform users of. They either see files or they don't
    fetchApi(supabase, '/integrations', '/sharepoint/request_permission_sync', {
      method: 'POST',
      workspaceId,
    }).catch((e) => {
      captureException(e);
    });

    refetchCurrentFolderContents();
  }

  useEffect(() => {
    async function fetchSites() {
      setLoadingSites(true);
      const { data, error } = await supabase
        .from('sharepoint_site_view')
        .select('*')
        .eq('workspace_id', workspaceId);

      if (error) {
        showToast({ title: t('general.tryAgainError'), status: 'error' });
        captureException(error);
        setAvailableSites([]);
        setLoadingSites(false);
        return;
      }

      setAvailableSites(data);
      setLoadingSites(false);
    }

    fetchSites();
  }, []);

  return (
    <div
      className={twMerge(
        'border-maia-border flex w-full flex-col border-b border-t',
        className
      )}
    >
      <div className="flex min-h-0 flex-grow">
        {/* ------------------------------------------------ Sites ------------------------------------------------ */}
        <div
          className={`border-maia-border flex flex-grow flex-col gap-2 overflow-y-auto border-r px-4 py-2 md:w-72 md:flex-grow-0 ${currentSiteId ? 'hidden md:flex' : ''}`}
        >
          <IconButton
            className="self-start md:hidden"
            icon={<FontAwesomeIcon icon={faArrowLeft} />}
            aria-label="back"
            variant={'icon-only'}
            onClick={onBackClick}
          />
          {loadingSites
            ? zeros(5).map((_, index) => {
                return (
                  <Skeleton key={index}>
                    <ListItem selected={false}>Loading</ListItem>
                  </Skeleton>
                );
              })
            : availableSites.map((site) => {
                const checkedStatus = selectedFileMap[site.id!]?.selected;

                return (
                  <ListItem
                    key={site.id}
                    selected={site.id === currentSiteId}
                    className="flex cursor-pointer select-none items-center gap-2 px-4 text-sm"
                  >
                    <Checkbox
                      colorScheme="maia-purple"
                      className="hover:bg-maia-purple-50 rounded"
                      isChecked={checkedStatus ?? false}
                      isIndeterminate={checkedStatus === null}
                      onChange={() => {
                        handleSiteSelectToggle(
                          site.id!,
                          currentSiteId,
                          breadCrumbs
                        );
                      }}
                    />
                    {/* The div hierarchy is a bit awkward here in order to separate the checkbox from the rest of the Listitem's content */}
                    <div
                      className="flex flex-grow items-center justify-between"
                      onClick={() => {
                        setCurrentSiteId(site.id!);
                      }}
                    >
                      <div className="flex items-center gap-2">
                        <FontAwesomeIcon
                          icon={faFolder}
                          className="text-chakra-gray-400 text-base"
                        />
                        <div>{site.name}</div>
                      </div>
                      <div className="text-chakra-gray-500 flex items-center gap-1">
                        <div>{site.file_count}</div>
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          className="text-xs"
                        />
                      </div>
                    </div>
                  </ListItem>
                );
              })}
        </div>
        {/* ------------------------------------------------ Folders and files ------------------------------------------------ */}
        <div
          className={`min-w-96 flex-grow px-4 py-2 ${!currentSiteId ? 'hidden md:flex' : ''}`}
        >
          <SharepointSiteFoldersAndFiles
            key={currentSiteId}
            breadCrumbs={breadCrumbs}
            files={files}
            folders={folders}
            loading={loadingFolderContent}
            onBackClick={() => {
              if (breadCrumbs.length) {
                handleGoBackInCrumbs(breadCrumbs.length - 2);
              } else {
                // Mobile: if already in root folder -> go back to site selection
                setCurrentSiteId(undefined);
              }
            }}
            onDeselectAllClick={() => {
              if (currentSiteId) {
                handleDeselectAll(breadCrumbs, currentSiteId);
              }
            }}
            onFileCheckboxClick={(fileId) => {
              if (currentSiteId) {
                handleFileSelectToggle(breadCrumbs, fileId, currentSiteId);
              }
            }}
            onFolderCheckboxClick={(folderId) => {
              if (currentSiteId) {
                handleFolderSelectToggle(breadCrumbs, folderId, currentSiteId);
              }
            }}
            onFolderClick={({ folderId, name }) => {
              handleGoToFolder({ folderId, name });
            }}
            onRefreshClick={handleFolderRefresh}
            onSelectAllClick={() => {
              if (currentSiteId) {
                handleSelectAll(breadCrumbs, currentSiteId);
              }
            }}
            onSelectFolderInCrumbs={(index) => {
              handleGoBackInCrumbs(index);
            }}
            selectedFileMap={
              currentSiteId ? selectedFileMap[currentSiteId]?.folders ?? {} : {}
            }
          />
        </div>
      </div>
    </div>
  );
}

export default SharepointSiteNavigator;
