import {
  FolderBreadCrumbs,
  isFileSelectable,
  stringPathFromBreadCrumbs,
} from './utils';
import { SetStateAction, useCallback, useState } from 'react';

import { Database, SharepointFileSelectionMap } from 'common-ts';
import { captureException } from '@sentry/react';
import { useBoundStore } from '../../../store/useBoundStore';
import { useToastManagerHook } from '../../../general/useToastManagerHook';
import { useTranslation } from 'react-i18next';

export default function useSharepointSelectionStateHandlers(
  selectedFileMap: SharepointFileSelectionMap,
  onUpdateSelectedFileMap: (
    updateValue: SetStateAction<SharepointFileSelectionMap>
  ) => void
) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceId = useBoundStore((state) => state.workspaceId);
  const { showToast } = useToastManagerHook();

  const [currentSiteId, setCurrentSiteId] = useState<string | undefined>(
    undefined
  );

  const [loading, setLoading] = useState(false);
  const [folders, setFolders] = useState<
    Database['public']['Views']['sharepoint_folder_view']['Row'][]
  >([]);
  const [files, setFiles] = useState<
    Database['public']['Views']['sharepoint_file_view']['Row'][]
  >([]);

  // List of folders highest to lowest level
  const [breadCrumbs, setBreadCrumbs] = useState<FolderBreadCrumbs>([]);
  const currentFolderId = breadCrumbs[breadCrumbs.length - 1]?.id;

  /**
   * Wrapper around `setCurrentSiteId`.
   * For navigation, you should use this instead of `setCurrentSiteId` because it synchronously updates the folder contents (and other dependent state) with the new siteId.
   *
   * @param newSiteId The uuid of the new site. (`sharepoint_site_view.id`)
   */
  function handleChangeCurrentSiteId(newSiteId: string | undefined) {
    setBreadCrumbs([]);
    setFolders([]);
    setFiles([]);
    setLoading(false);

    setCurrentSiteId(newSiteId);
    if (newSiteId) {
      fetchFoldersAndFiles(newSiteId, undefined, []);
    }
  }

  /**
   * Wrapper around `setBreadCrumbs`. For navigation, you should use this instead of `setBreadCrumbs` because it synchronously updates the folder contents with the changed breadcrumbs.
   * @param newBreadCrumbs The updated breadCrumbs or a function to update them based on the previous breadCrumbs.
   */
  function handleChangeBreadCrumbs(
    newBreadCrumbs: SetStateAction<FolderBreadCrumbs>
  ) {
    if (typeof newBreadCrumbs === 'function') {
      setBreadCrumbs((prev) => {
        const updatedBreadCrumbs = newBreadCrumbs(prev);

        fetchFoldersAndFiles(
          currentSiteId!,
          updatedBreadCrumbs[updatedBreadCrumbs.length - 1]?.id,
          updatedBreadCrumbs
        );
        return updatedBreadCrumbs;
      });
    } else {
      setBreadCrumbs(newBreadCrumbs);
      fetchFoldersAndFiles(
        currentSiteId!,
        newBreadCrumbs[newBreadCrumbs.length - 1]?.id,
        newBreadCrumbs
      );
    }
  }

  /**
   * Fetch folders and files contained within the current folder.
   * The 'current' folder is based on the `breadcrumbs`.
   * Can be used for initial fetch or subsequent refetches.
   */
  const fetchFoldersAndFiles = useCallback(
    async (
      siteId: string,
      folderId: string | undefined,
      breadCrumbs: FolderBreadCrumbs
    ) => {
      setLoading(true);
      if (!siteId) {
        setFolders([]);
        setFiles([]);
        setLoading(false);
        return;
      }

      const folderSelect = supabase
        .from('sharepoint_folder_view')
        .select('*')
        .eq('workspace_id', workspaceId)
        .eq('site_id', siteId);

      if (folderId) {
        folderSelect.eq('parent_id', folderId);
      } else {
        folderSelect.is('parent_id', null);
      }

      const { data: folderData, error: folderError } = await folderSelect;

      if (folderError) {
        showToast({ title: t('general.tryAgainError'), status: 'error' });
        captureException(folderError);
        setFolders([]);
        setLoading(false);
        return;
      }

      if (!folderId) {
        handleUpdateCurrentFolderFiles(siteId, [], folderData, breadCrumbs);
        setLoading(false);
        return;
      } else {
        const { data: fileData, error: fileError } = await supabase
          .from('sharepoint_file_view')
          .select('*')
          .eq('workspace_id', workspaceId)
          .eq('folder_id', folderId);

        if (fileError) {
          showToast({ title: t('general.tryAgainError'), status: 'error' });
          captureException(fileError);
          setFiles([]);
          setLoading(false);
          return;
        }

        handleUpdateCurrentFolderFiles(
          siteId,
          fileData,
          folderData,
          breadCrumbs
        );
        setLoading(false);
      }
    },
    [workspaceId]
  );

  /**
   * Function to refetch the content of the *current* folder.
   * Only use in cases where no navigation is happening (siteId and current folder are not changing).
   * Might produce unexpected results otherwise because of async state updates.
   */
  const refetchCurrentFolderContents = useCallback(async () => {
    if (currentSiteId) {
      fetchFoldersAndFiles(currentSiteId, currentFolderId, breadCrumbs);
    }
  }, [currentSiteId, currentFolderId, breadCrumbs, fetchFoldersAndFiles]);

  /**
   * Use when files and folders contained in a folder have been loaded.
   * Sets the files that will be displayed to fileData and the folders that will be displayed to folderData.
   * Takes all necessary steps to correctly update `selectedFileMap`. No matter if the files and folders are loaded for the first time or have been seen before.
   * `FileData` and `folderData` must represent to contents of the current folder (as determined by `currentBreadcrumbs`) in order for the logic surrounding `selectedFileMap` to work correctly.
   *
   * !breadcrumbs are passed as an argument and not taken from state.
   * This is to ensure that the proper path at the time of calling this function is used, instead of possibly out of sync state.
   *
   * @param siteId The id of the current site
   * @param fileData The files contained in the current folder.
   * @param folderData The folders contained in the current folder.
   * @param currentBreadCrumbs Breadcrumbs representing the path to the current folder.
   */
  function handleUpdateCurrentFolderFiles(
    siteId: string,
    fileData: Database['public']['Views']['sharepoint_file_view']['Row'][],
    folderData: Database['public']['Views']['sharepoint_folder_view']['Row'][],
    currentBreadCrumbs: FolderBreadCrumbs
  ) {
    setFiles(fileData);
    setFolders(folderData);

    const { folderPath } = stringPathFromBreadCrumbs(currentBreadCrumbs);

    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      if (!prevSelectedFileMap[siteId]) {
        prevSelectedFileMap[siteId]! = { selected: false, folders: {} };
      }

      const siteSelectionInfo = prevSelectedFileMap[siteId]!;
      const folderSelectionInfo = siteSelectionInfo.folders[folderPath];

      // Folder has not been opened before -> no selection info in state yet
      // Should only happen for root folder
      if (!folderSelectionInfo) {
        siteSelectionInfo.folders[folderPath] = {
          selected: !!siteSelectionInfo.selected,
          files: Object.fromEntries(
            fileData
              .filter((file) => isFileSelectable(file))
              .map((file) => {
                return [file.id!, !!siteSelectionInfo.selected];
              })
          ),
        };
      }
      // Folder has already been opened OR marked as selected -> update with missing files
      else {
        const updatedFileMap = Object.fromEntries(
          fileData
            .filter((file) => isFileSelectable(file))
            .map((file) => {
              return [
                file.id!,
                folderSelectionInfo.selected === null
                  ? folderSelectionInfo.files[file.id!] ?? false
                  : !!folderSelectionInfo.selected,
              ];
            })
        );

        prevSelectedFileMap[siteId]!.folders[folderPath] = {
          selected: folderSelectionInfo.selected,
          files: {
            ...folderSelectionInfo.files,
            ...updatedFileMap,
          },
        };
      }

      for (const childFolder of folderData) {
        const childFolderPath = `${folderPath}_${childFolder.id!}`;

        const childFolderInfo =
          prevSelectedFileMap[siteId]!.folders[childFolderPath];
        // Child folder not seen yet
        if (!childFolderInfo) {
          prevSelectedFileMap[siteId]!.folders[childFolderPath] = {
            selected:
              !!prevSelectedFileMap[siteId]!.folders[folderPath]!.selected,
            files: {},
          };
        } else {
          prevSelectedFileMap[siteId]!.folders[childFolderPath]!.selected =
            prevSelectedFileMap[siteId]!.folders[folderPath]?.selected === null
              ? prevSelectedFileMap[siteId]!.folders[childFolderPath]
                  ?.selected ?? null
              : prevSelectedFileMap[siteId]!.folders[folderPath]!.selected;
        }
      }

      return { ...prevSelectedFileMap };
    });
  }

  /**
   * Handles the selection / deselection of an entire folder
   * @param currentBreadCrumbs Breadcrumbs representing the path to the current folder. !This is the folder the user is currently seeing the contents of, not the one being selected.
   * @param folderId Id of the folder to be selected.
   */
  function handleFolderSelectToggle(
    currentBreadCrumbs: FolderBreadCrumbs,
    folderId: string,
    siteId: string
  ) {
    const folderPath = `${stringPathFromBreadCrumbs(currentBreadCrumbs).folderPath}_${folderId}`;

    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      // Only set folder itself to selected. Selection state of contained files is handled in case the folder is opened.
      prevSelectedFileMap[siteId]!.folders[folderPath]!.selected =
        !prevSelectedFileMap[siteId]!.folders[folderPath]!.selected;

      // Go up the hierarchy to set all parent folders to indeterminate
      // This may lead to folders having the indeterminate state even though all their children are selected
      // But in order to eliminate this inconsistency, we would need to recursively go through all their children and check their selected states, which could have a huge performance impact.
      Object.keys(prevSelectedFileMap[siteId]!.folders).forEach((pathKey) => {
        if (pathKey !== folderPath && folderPath.startsWith(pathKey)) {
          prevSelectedFileMap[siteId]!.folders[pathKey]!.selected = null;
        }
      });

      // Set site to indeterminate
      prevSelectedFileMap[siteId]!.selected = null;

      return { ...prevSelectedFileMap };
    });
  }

  /**
   * Handles all state updates necessary to select / deselect a file.
   *
   * @param currentBreadCrumbs Breadcrumbs representing the path to the current folder.
   * @param folderId Id of the folder to be selected.
   * @param siteId Id of the current site.
   */
  function handleFileSelectToggle(
    currentBreadCrumbs: FolderBreadCrumbs,
    fileId: string,
    siteId: string
  ) {
    const folderPath = stringPathFromBreadCrumbs(currentBreadCrumbs).folderPath;

    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      prevSelectedFileMap[siteId]!.folders[folderPath]!.files[fileId] =
        !prevSelectedFileMap[siteId]!.folders[folderPath]!.files[fileId];

      // Go up the hierarchy to set all parent folders to indeterminate
      // See `handleFolderSelectToggle` for caveats
      Object.keys(prevSelectedFileMap[siteId]!.folders).forEach((pathKey) => {
        if (folderPath.startsWith(pathKey)) {
          prevSelectedFileMap[siteId]!.folders[pathKey]!.selected = null;
        }
      });

      // Set site to indeterminate
      prevSelectedFileMap[siteId]!.selected = null;

      return { ...prevSelectedFileMap };
    });
  }

  /**
   * Handles the navigation to a folder that is a direct child of the currently viewed folder.
   * @param folderId The id of the folder to navigate to
   * @param name The name of the folder to navigate to
   */
  function handleGoToFolder({
    folderId,
    name,
  }: {
    folderId: string;
    name: string;
  }) {
    handleChangeBreadCrumbs((prev) => {
      return [...prev, { id: folderId, name }];
    });
  }

  /**
   * Handles going back in the current folder hierarchy based on the breadcrumbs.
   * @param index The index of the breadcrumb representing the folder to navigate to.
   */
  function handleGoBackInCrumbs(index: number) {
    handleChangeBreadCrumbs((prev) => {
      return prev.slice(0, index + 1);
    });
  }

  /**
   * Performs all state updates necessary to select all folders and files contained within the currently viewed folder.
   *
   * @param currentBreadCrumbs Breadcrumbs representing the path to the current folder.
   * @param siteId Id of the current site
   */
  function handleSelectAll(
    currentBreadCrumbs: FolderBreadCrumbs,
    siteId: string
  ) {
    const { folderPath } = stringPathFromBreadCrumbs(currentBreadCrumbs);

    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      // Set all entries in the current folder to selected (all contained files)
      Object.keys(
        prevSelectedFileMap[siteId]!.folders[folderPath]!.files
      ).forEach((key) => {
        prevSelectedFileMap[siteId]!.folders[folderPath]!.files[key] = true;
      });

      // Set the folder itself to selected
      prevSelectedFileMap[siteId]!.folders[folderPath]!.selected = true;

      Object.keys(prevSelectedFileMap[siteId]!.folders).forEach((pathKey) => {
        // Set all parent folders to indeterminate
        // See `handleFolderSelectToggle` for caveats
        if (pathKey !== folderPath && folderPath.startsWith(pathKey)) {
          prevSelectedFileMap[siteId]!.folders[pathKey]!.selected =
            prevSelectedFileMap[siteId]!.folders[pathKey]!.selected === null ||
            prevSelectedFileMap[siteId]!.folders[pathKey]!.selected === false
              ? null
              : true;
        }
        // Set all child folders to selected
        else if (pathKey.startsWith(folderPath)) {
          prevSelectedFileMap[siteId]!.folders[pathKey]!.selected = true;
        }
      });

      return { ...prevSelectedFileMap };
    });
  }

  /**
   * Performs all state updates necessary to deselect all folders and files contained within the currently viewed folder.
   *
   * @param currentBreadCrumbs Breadcrumbs representing the path to the current folder.
   * @param siteId The id of the current site
   */
  function handleDeselectAll(
    currentBreadCrumbs: FolderBreadCrumbs,
    siteId: string
  ) {
    const { folderPath } = stringPathFromBreadCrumbs(currentBreadCrumbs);

    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      // Set all entries in the current folder to not selected (all contained files)
      Object.keys(
        prevSelectedFileMap[siteId]!.folders[folderPath]!.files
      ).forEach((key) => {
        prevSelectedFileMap[siteId]!.folders[folderPath]!.files[key] = false;
      });

      // Set the folder itself to not selected
      prevSelectedFileMap[siteId]!.folders[folderPath]!.selected = false;

      Object.keys(prevSelectedFileMap[siteId]!.folders).forEach((pathKey) => {
        // Set all parent folders to indeterminate
        // See `handleFolderSelectToggle` for caveats
        if (pathKey !== folderPath && folderPath.startsWith(pathKey)) {
          prevSelectedFileMap[siteId]!.folders[pathKey]!.selected =
            prevSelectedFileMap[siteId]!.folders[pathKey]!.selected === null ||
            prevSelectedFileMap[siteId]!.folders[pathKey]!.selected === true
              ? null
              : false;
        }
        // Set all child folders to not selected
        else if (pathKey.startsWith(folderPath)) {
          prevSelectedFileMap[siteId]!.folders[pathKey]!.selected = false;
        }
      });

      return { ...prevSelectedFileMap };
    });
  }

  /**
   * Performs all state updates necessary to select / deselect an entire site
   *
   * @param siteId The id of the site to be selected
   * @param currentlyViewingSiteId The id of the site that is currently being traversed
   * @param currentBreadCrumbs Breadcrumbs representing the path to the current folder
   */
  function handleSiteSelectToggle(
    siteId: string,
    currentlyViewingSiteId: string | undefined,
    currentBreadCrumbs: FolderBreadCrumbs
  ) {
    const { folderPath } = stringPathFromBreadCrumbs(currentBreadCrumbs);

    onUpdateSelectedFileMap((prevSelectedFileMap) => {
      let select = true;
      if (!prevSelectedFileMap[siteId]) {
        prevSelectedFileMap[siteId] = { selected: select, folders: {} };
      } else {
        select = !prevSelectedFileMap[siteId]!.selected;
        prevSelectedFileMap[siteId]!.selected = select;
      }

      if (siteId === currentlyViewingSiteId && folderPath) {
        // If user is viewing a folder in the site to be selected
        // Set all entries in the current folder to selected (all contained files)
        Object.keys(
          prevSelectedFileMap[siteId]!.folders[folderPath]!.files
        ).forEach((key) => {
          prevSelectedFileMap[siteId]!.folders[folderPath]!.files[key] = select;
        });

        // Set the folder itself to selected
        prevSelectedFileMap[siteId]!.folders[folderPath]!.selected = select;
      }

      // Set all folders of the site that are already present in the selectedFileMap to selected
      Object.keys(prevSelectedFileMap[siteId]!.folders).forEach((key) => {
        if (key !== 'selected') {
          prevSelectedFileMap[siteId]!.folders[key]!.selected = select;
        }
      });

      return { ...prevSelectedFileMap };
    });
  }

  return {
    folders,
    files,
    loading,
    selectedFileMap,
    currentSiteId,
    breadCrumbs,
    setCurrentSiteId: handleChangeCurrentSiteId,
    handleFolderSelectToggle,
    handleFileSelectToggle,
    handleGoToFolder,
    handleGoBackInCrumbs,
    handleSelectAll,
    handleDeselectAll,
    handleSiteSelectToggle,
    refetchCurrentFolderContents,
  };
}
