import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist';
import { encodeName, planFileSizeLimitMbMap } from 'common-ts';

import AdaUsage from '../../components/ada/AdaUsage.js';
import Feature from '../../components/Feature.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TempFileObject } from './FileRow.js';
import { faArrowUpFromBracket } from '@fortawesome/pro-regular-svg-icons';
// @ts-ignore
import pdfWorker from 'pdfjs-dist/build/pdf.worker.js';
import { useBoundStore } from '../../store/useBoundStore.js';
import { useEffect } from 'react';
import { useToastManagerHook } from '../../general/useToastManagerHook.js';
import { useTranslation } from 'react-i18next';
import { MEGABYTE_IN_BYTES } from 'common-ts';

GlobalWorkerOptions.workerSrc = pdfWorker;

type FileUploadModalProps = {
  isOpen: boolean;
  collectionId: string;
  usedStorageMb: number;
  storageLimitMb: number;
  existingFileNames: string[];
  onClose: () => void;
  onFilesStartedUploading?: (files: TempFileObject[]) => void;
  onFilesFinishedUploading?: () => void;
  onFreeStorageLimitReached: () => void;
  onFreeUserMultiFileAttempt: () => void;
};

function FileUploadModal({
  isOpen,
  collectionId,
  usedStorageMb,
  storageLimitMb,
  existingFileNames,
  onClose,
  onFilesStartedUploading,
  onFilesFinishedUploading,
  onFreeStorageLimitReached,
  onFreeUserMultiFileAttempt,
}: FileUploadModalProps) {
  const { t } = useTranslation();
  const supabase = useBoundStore((state) => state.supabase);
  const workspaceLicenseType = useBoundStore(
    (state) => state.workspaceLicenseType
  );
  const { showToast } = useToastManagerHook();

  useEffect(() => {
    const onDragOver = (e: DragEvent) => {
      e.preventDefault();
    };
    const onDrop = (e: DragEvent) => {
      e.stopPropagation();
      e.preventDefault();
      handleFileSelect(e.dataTransfer?.files);
    };

    if (isOpen) {
      window.addEventListener('dragover', onDragOver);
      window.addEventListener('drop', onDrop);
    }

    return () => {
      window.removeEventListener('dragover', onDragOver);
      window.removeEventListener('drop', onDrop);
    };
  }, [isOpen]);

  /**
   * Opens the file upload dialog and uploads the selected files to the selected collection.
   * If the total size of the files exceeds the storage limit, an alert dialog is shown.
   */
  function handleFileUploadClick() {
    const input = document.createElement('input');
    if (workspaceLicenseType !== 'FREE') {
      input.multiple = true;
    }
    input.type = 'file';
    input.accept = '.txt, .pdf, .docx';
    input.onchange = () => {
      if (input.files) {
        handleFileSelect(input.files);
      }
    };

    input.click();
    // TODO destroy input element?
  }

  async function handleFileSelect(files: FileList | undefined) {
    if (!files || files.length === 0) {
      showToast({
        title: t('fileManagerPanel.fileUploadModal.noFilesSelectedWarning'),
        status: 'warning',
      });
      return;
    }
    if (workspaceLicenseType === 'FREE' && files.length > 1) {
      onFreeUserMultiFileAttempt();
      return;
    }

    if (existingFileNames.length + files.length > 1000) {
      showToast({
        title: `${t('fileManagerPanel.tooManyFilesError')}`,
        status: 'error',
      });
      return;
    }

    let totalFileSize = 0;
    const fileList: File[] = [];
    const tooBigFiles: File[] = [];
    const tooLongFiles: File[] = [];

    for (const f of files) {
      // Check for files that exceed the size or page limit
      const fileSizeMb = f.size / MEGABYTE_IN_BYTES;
      if (fileSizeMb > planFileSizeLimitMbMap[workspaceLicenseType ?? 'FREE']) {
        tooBigFiles.push(f);
      } else if (f.type === 'application/pdf') {
        const pdf = await getDocument(await f.arrayBuffer()).promise;
        if (pdf.numPages > 2000) {
          tooLongFiles.push(f);
        } else {
          totalFileSize += fileSizeMb;
          fileList.push(f);
        }
      } else {
        totalFileSize += fileSizeMb;
        fileList.push(f);
      }
    }

    // Display toasts for files that are too big
    if (tooBigFiles.length > 0) {
      tooBigFiles.forEach((file) => {
        showToast({
          title: `${t('fileManagerPanel.uploadFileSizeError', {
            filename: file.name,
            limit: `${planFileSizeLimitMbMap[workspaceLicenseType ?? 'FREE']}MB`,
          })}`,
          status: 'error',
        });
      });
    }

    // Display toasts for files that are too long
    if (tooLongFiles.length > 0) {
      tooLongFiles.forEach((file) => {
        showToast({
          title: `${t('fileManagerPanel.uploadPageCountError', {
            filename: file.name,
            limit: '2000',
          })}`,
          status: 'error',
        });
      });
    }

    if (totalFileSize + usedStorageMb > storageLimitMb) {
      if (workspaceLicenseType === 'FREE') {
        onFreeStorageLimitReached();
      } else {
        showToast({
          title: t('fileManagerPanel.storageLimitReached'),
          status: 'error',
        });
      }
      return;
    }

    const tempFiles = fileList
      .map((file) => {
        const tempFile: TempFileObject = {
          name: file.name,
          type: file.type,
          status: { type: 'UPLOADING' },
        };
        return tempFile;
      })
      .filter(
        (file) =>
          !existingFileNames.some(
            (existingFileName) => existingFileName === file.name
          )
      );

    onFilesStartedUploading?.(tempFiles);

    const promises = fileList.map((file) => {
      const { data: name, error: encodeNameError } = encodeName(file.name);
      if (encodeNameError) {
        console.error(encodeNameError);
      }
      return supabase.storage
        .from(collectionId)
        .upload(name || file.name, file);
    });

    /* Wait for all files to upload
     before calling finished uploading callback
     otherwise, it would trigger overwrite
     large files that are being uploaded would disappear */

    const responses = await Promise.all(promises);

    const err = responses[0]?.error;

    if (err) {
      showToast({
        title: `${t('fileManagerPanel.collectionDuplicate')}`,
        status: 'warning',
      });
    }

    onFilesFinishedUploading?.();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('fileManagerPanel.fileUploadModal.header')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="flex flex-col gap-2.5">
            <div className="border-maia-border bg-maia-gray-100 flex h-44 flex-col items-center justify-center gap-3 rounded-xl border">
              <FontAwesomeIcon
                icon={faArrowUpFromBracket}
                className="text-maia-gray-400 text-5xl"
              />
              <div className="font-semibold">
                {t('fileManagerPanel.fileUploadModal.dndInfo')}{' '}
                <span
                  className="text-maia-accent cursor-pointer select-none"
                  onClick={handleFileUploadClick}
                >
                  {t('fileManagerPanel.fileUploadModal.select')}
                </span>
              </div>
              <div className="text-chakra-gray-500 text-xs">
                {t('fileManagerPanel.fileUploadModal.supportedTypes')}
              </div>
            </div>
            <Feature name="analyze">
              <AdaUsage />
            </Feature>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex items-center justify-end gap-3">
            <Button variant="outline" onClick={onClose}>
              {t('general.cancelButton')}
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default FileUploadModal;
